html {
	background: linear-gradient(90deg, #ffffffaa, #ffffaa33);
}

.regio-background {
	background-repeat: no-repeat;
	background-size: contain;
	height: 100vh;
	width: 100vw;
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -99;

	display: flex;
	justify-content: center;
	flex-direction: column;
}

.regio-bg-header {
	padding-top: 250px;
	height: 150px;
	width: 100vw;
	background-image: url('https://www.regioschau-kraichgau.de/wp-content/uploads/2017/10/border05.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center 100%;

	animation: welleHochRunter 15s ease-in-out infinite;
}

.regio-bg-footer {
	flex-grow: 1;
	background-color: #4fac2500;
}
.logo-click {
	position: absolute;
	bottom: 0;
	right: 0;
}

@keyframes welleHochRunter {
	0% {
		padding-top: 30px;
	}	
	50% {
		padding-top: 150px;
	}
	100% {
		padding-top: 30px;
	}
}